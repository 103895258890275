.register {
  overflow-y: auto;
}

.register label {
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  padding-bottom: 3em;
  margin-bottom: 3em;
}

.buttons button {
  height: 3em;
}
